<template>
  <div class="col-lg-8">
    <div class="row">
      <div class="col-12">
        <div class="dash-pro-item mb-30 dashboard-widget">
          <div class="header">
            <h4 class="title">Informações pessoais</h4>
            <!-- <span class="edit"><i class="flaticon-edit"></i> Editar</span> -->
          </div>
          <form
            class="login-form"
            @submit.prevent="cadastro"
            v-if="getUsuarioInfo"
          >
            <div class="row">
              <div class="form-group mb-30 col-md-12">
                <label for="nome-completo"><i class="fas fa-user"></i></label>
                <input
                  type="text"
                  id="nome-completo"
                  placeholder="Nome completo"
                  v-model="nomeCompleto"
                  required
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group mb-30 col-md-6">
                <label for="data-nascimento"
                  ><i class="fas fa-calendar-alt"></i
                ></label>
                <input
                  type="text"
                  id="data-nascimento"
                  placeholder="Data nascimento"
                  v-model="dataNascimento"
                  v-mask="'##/##/####'"
                  required
                />
              </div>
              <div class="form-group mb-30 col-md-6">
                <label for="genero"><i class="fas fa-venus-mars"></i></label>
                <select id="genero" v-model="genero" required>
                  <option value="">Selecione</option>
                  <option value="F">Feminino</option>
                  <option value="M">Masculino</option>
                  <option value="O">Outro</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="form-group mb-30 col-md-6">
                <label for="telefone"><i class="fas fa-phone-alt"></i></label>
                <input
                  type="text"
                  id="telefoneCelular"
                  placeholder="Celular"
                  v-model="telefoneCelular"
                  v-mask="'(##) #####-####'"
                  required
                />
              </div>
              <div class="form-group mb-30 col-md-6">
                <label for="telefone"><i class="fas fa-phone-alt"></i></label>
                <input
                  type="text"
                  id="telefone"
                  placeholder="Telefone"
                  v-model="telefone"
                  v-mask="'(##) #####-####'"
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group mb-30 col-md-6">
                <label for="tipoPessoa"><i class="fas fa-users-cog"></i></label>
                <select id="tipoPessoa" v-model="tipoPessoa" required>
                  <option value="PF">Pessoa Física</option>
                  <option value="PJ">Pessoa Juridica</option>
                </select>
              </div>
              <div class="form-group mb-30 col-md-6">
                <label for="cpf"><i class="fas fa-id-card"></i></label>
                <input
                  v-if="tipoPessoa == 'PF'"
                  type="text"
                  id="cpf"
                  placeholder="CPF"
                  v-model="numeroDocumento"
                  v-mask="'###.###.###-##'"
                  required
                />
                <input
                  v-if="tipoPessoa == 'PJ'"
                  type="text"
                  id="cpf"
                  placeholder="CNPJ"
                  v-model="numeroDocumento"
                  v-mask="'##.###.###/####-##'"
                  required
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group mb-30 col-md-4">
                <label for="rg"><i class="fas fa-id-card"></i></label>
                <input
                  type="text"
                  id="rg"
                  placeholder="RG"
                  v-model="rg"
                  required
                />
              </div>
              <div class="form-group mb-30 col-md-4">
                <label for="orgao-emissor"
                  ><i class="fas fa-users-cog"></i
                ></label>
                <input
                  type="text"
                  id="orgao-emissor"
                  placeholder="Orgão Emissor"
                  v-model="orgaoEmissor"
                  required
                />
              </div>
              <div class="form-group mb-30 col-md-4">
                <label for="data-emissao"
                  ><i class="fas fa-calendar-alt"></i
                ></label>
                <input
                  type="text"
                  id="data-emissao"
                  placeholder="Data emissão"
                  v-model="dataEmissor"
                  v-mask="'##/##/####'"
                  required
                />
              </div>
            </div>

            <div class="header">
              <h4 class="title">Endereço</h4>
              <!-- <span class="edit"><i class="flaticon-edit"></i> Editar</span> -->
            </div>

            <div class="row">
              <div class="form-group mb-30 col-md-4">
                <label for="form-cep"
                  ><i class="fas fa-location-arrow"></i
                ></label>
                <input
                  type="text"
                  id="form-cep"
                  placeholder="cep"
                  v-model="endereco.cep"
                  required
                  v-mask="'#####-###'"
                  v-on:change="searchCep"
                />
                <span class="pass-type" v-if="getLoadingCep"
                  ><i class="fa fa-spin fa-spinner"></i
                ></span>
              </div>

              <div class="form-group mb-30 col-12 col-md-8">
                <label for="form-logradouro"><i class="fas fa-road"></i></label>
                <input
                  type="text"
                  id="form-logradouro"
                  placeholder="Logradouro"
                  v-model="endereco.logradouro"
                  required
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group mb-30 col-md-4">
                <label for="form-numero"><i class="fas fa-map-pin"></i></label>
                <input
                  type="text"
                  id="form-numero"
                  placeholder="Número"
                  v-model="endereco.numero"
                  required
                />
              </div>
              <div class="form-group mb-30 col-12 col-md-8">
                <label for="form-complemento"
                  ><i class="fas fa-map-signs"></i
                ></label>
                <input
                  type="text"
                  id="form-complemento"
                  placeholder="Complemento"
                  v-model="endereco.complemento"
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group mb-30 col-md-4">
                <label for="form-bairro"><i class="fas fa-building"></i></label>
                <input
                  type="text"
                  id="form-bairro"
                  placeholder="Bairro"
                  v-model="endereco.bairro"
                  required
                />
              </div>
              <div class="form-group mb-30 col-md-5">
                <label for="form-cidade"><i class="fas fa-city"></i></label>
                <input
                  type="text"
                  id="form-cidade"
                  placeholder="Cidade"
                  v-model="endereco.cidade"
                  required
                />
              </div>
              <div class="form-group mb-30 col-12 col-md-3">
                <label for="form-estado"><i class="fas fa-flag"></i></label>
                <select id="form-estado" v-model="endereco.estado" required>
                  <option value="">Estado</option>
                  <option value="AC">AC</option>
                  <option value="AL">AL</option>
                  <option value="AP">AP</option>
                  <option value="AM">AM</option>
                  <option value="BA">BA</option>
                  <option value="CE">CE</option>
                  <option value="DF">DF</option>
                  <option value="ES">ES</option>
                  <option value="GO">GO</option>
                  <option value="MA">MA</option>
                  <option value="MT">MT</option>
                  <option value="MS">MS</option>
                  <option value="MG">MG</option>
                  <option value="PR">PR</option>
                  <option value="PB">PB</option>
                  <option value="PA">PA</option>
                  <option value="PE">PE</option>
                  <option value="PI">PI</option>
                  <option value="RJ">RJ</option>
                  <option value="RN">RN</option>
                  <option value="RS">RS</option>
                  <option value="RO">RO</option>
                  <option value="RR">RR</option>
                  <option value="SC">SC</option>
                  <option value="SE">SE</option>
                  <option value="SP">SP</option>
                  <option value="TO">TO</option>
                </select>
              </div>
            </div>
            <div class="form-group mb-30">
              <button type="submit" class="custom-button">
                <i class="fa fa-spin fa-spinner" v-if="loading == true" />
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import http from '@/service/http/http';
// import estados from '../../../config/config';
import moment from 'moment';
import Notification from '@/mixins/notification';
moment.locale('pt-br');
export default {
  name: 'edit-perfil',
  data() {
    return {
      usuarioId: '',
      identityId: '',
      nomeCompleto: '',
      dataNascimento: '',
      telefone: '',
      telefoneCelular: '',
      dataEmissor: '',
      orgaoEmissor: '',
      rg: '',
      numeroDocumento: '',
      genero: '',
      // estados: estados,
      endereco: {
        cep: '',
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        estado: '',
      },
      tipoPessoa: '',
      loading: false,
    };
  },
  mixins: [Notification],
  computed: {
    ...mapGetters(['getUsuarioInfo', 'getLoadingCep', 'getAuth']),
    getRg() {
      return this.getUsuarioInfo?.documentos?.find((e) => e.tipo == 'RG');
    },
    getCpf() {
      return this.getUsuarioInfo?.documentos?.find((e) => e.tipo == 'CPF');
    },
  },
  methods: {
    ...mapActions(['setCadastro', 'loadCep', 'info']),
    async cadastro() {
      this.loading = true;
      const payload = {
        usuarioId: this.usuarioId,
        identityId: this.identityId,
        nomeCompleto: this.nomeCompleto,
        email: this.email,
        dataNascimento: moment(this.dataNascimento, 'DD/MM/YYYY').toISOString(),
        dataEmissao: moment(this.dataEmissor, 'DD/MM/YYYY').toISOString(),
        orgaoEmissor: this.orgaoEmissor,
        telefoneCelular: this.telefoneCelular,
        telefoneConvencional: this.telefone,
        rg: this.rg,
        genero: this.genero,
        numeroDocumento: this.numeroDocumento,
        tipoPessoa: this.tipoPessoa,
        endereco: {
          cep: this.endereco.cep,
          logradouro: this.endereco.logradouro,
          numero: this.endereco.numero,
          complemento: this.endereco.complemento,
          bairro: this.endereco.bairro,
          cidade: this.endereco.cidade,
          estado: this.endereco.estado,
        },
        perfis: [1],
        emailConfirmado: true,
        ativo: true,
      };
      try {
        await http.put(`usuario/${this.identityId}/atualizar`, payload);
        this.loading = false;
        this.showToast({ title: 'Perfil atualizado com sucesso' });
        this.$router.push({ name: 'perfil' });
      } catch (_) {
        this.loading = false;
        this.showToast({ title: 'Erro ao atualizar o perfil' });
      }

      this.loading = false;
    },

    searchCep(event) {
      if (event) {
        event.preventDefault();
      }

      if (this.endereco.cep.length === 9) {
        this.loadCep(this.endereco.cep)
          .then((resp) => {
            this.endereco.logradouro = resp.logradouro;
            this.endereco.bairro = resp.bairro;
            this.endereco.cidade = resp.localidade;
            this.endereco.estado = resp.uf;
          })
          .catch(() => {
            this.endereco.logradouro = '';
            this.endereco.bairro = '';
            this.endereco.cidade = '';
            this.endereco.estado = '';
          });
      }
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    switchVisibility2() {
      this.passwordFieldType2 =
        this.passwordFieldType2 === 'password' ? 'text' : 'password';
    },
    getUsuario(usuarioId) {
      return http({
        url: `usuario/${usuarioId}`,
        method: 'get',
      });
    },
  },

  async created() {
    await this.info();

    this.usuarioId = this.getUsuarioInfo?.usuarioId;
    this.identityId = this.getAuth.uid;
    this.nomeCompleto = this.getUsuarioInfo?.nomeCompleto;
    this.dataNascimento = moment(
      this.getUsuarioInfo?.dataNascimento,
      'YYYY-MM-DD'
    ).format('DD/MM/YYYY');
    this.dataEmissor = moment(this.getRg?.dataEmissao, 'YYYY-MM-DD').format(
      'DD/MM/YYYY'
    );
    this.orgaoEmissor = this.getRg?.orgaoEmissor;
    this.rg = this.getRg?.numeroDocumento;
    this.tipoPessoa = this.getUsuarioInfo?.tipoPessoa;
    this.numeroDocumento = this.getCpf?.numeroDocumento;
    this.endereco.cep = this.getUsuarioInfo?.endereco.cep;
    this.endereco.logradouro = this.getUsuarioInfo?.endereco.logradouro;
    this.endereco.numero = this.getUsuarioInfo?.endereco.numero;
    this.endereco.complemento = this.getUsuarioInfo?.endereco.complemento;
    this.endereco.bairro = this.getUsuarioInfo?.endereco.bairro;
    this.endereco.cidade = this.getUsuarioInfo?.endereco.cidade;
    this.endereco.estado = this.getUsuarioInfo?.endereco.estado;
    this.email = this.getAuth?.email;
    this.genero = this.getUsuarioInfo?.genero;
    this.telefoneCelular = this.getUsuarioInfo?.telefone;
    this.telefone = this.getUsuarioInfo?.telefoneConvencional;
  },

  filters: {
    formatData(data) {
      if (data) return data.substring(0, 10).split('-').reverse().join('/');
    },
    formatTelefone(tel) {
      if (!tel || tel.length < 11) return '';

      if (typeof tel !== 'string') tel = tel.toString();

      tel = tel.replace(/\D/g, '');

      return tel.replace(/(\d{2})(\d{5})(\d{4})/, '($1)$2-$3');
    },
  },
};
</script>

<style scoped>
.login-form .form-group input,
select {
  padding-left: 40px;
}
</style>
